import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QueryParamsHandling, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
	selector: 'yuno-ui-selector',
	standalone: true,
	imports: [CommonModule],
	template: `
		<div class="inline-flex flex-row rounded-full bg-yuno-blue-gray-300 p-1 text-sm">
			<ng-content></ng-content>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoUiSelectorComponent {}

@Component({
	selector: 'yuno-ui-selector-item',
	standalone: true,
	imports: [CommonModule, RouterLink, RouterLinkActive],
	template: `
		<a
			class="inline-block rounded-full px-4 py-1"
			[routerLink]="link"
			[routerLinkActive]="'bg-yuno-portal-blue text-white'"
			[queryParamsHandling]="queryParamsHandling">
			<ng-content></ng-content>
		</a>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoUiSelectorItemComponent {
	@Input({ required: true }) link: string | string[];
	@Input() queryParamsHandling: QueryParamsHandling = 'merge';
}
