import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'yuno-ui-overlay-modal',
	standalone: true,
	template: `
		<div
			class="pointer-events-auto flex w-full flex-col rounded-md bg-yuno-blue-gray-100 px-4 py-8 shadow">
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./overlay.modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoUiOverlayModalComponent {}
