import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { LoadersModule } from '@yuno/project-atlas/ui';

@Component({
	selector: 'yuno-ui-list-item',
	standalone: true,
	imports: [NgClass],
	template: `
		<div
			class="flex w-full select-none flex-row items-center gap-4 overflow-hidden rounded-md border border-yuno-blue-gray-400 bg-yuno-blue-gray-300 hover:border-yuno-blue-gray-500 hover:bg-yuno-blue-gray-400">
			<ng-content></ng-content>
			<div class="flex gap-4 empty:hidden">
				<ng-content select="[actions]"></ng-content>
			</div>
		</div>
	`,
	styles: `
		:host {
			display: list-item;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoListItemComponent {}

@Component({
	selector: 'yuno-ui-list-item-loader',
	standalone: true,
	imports: [LoadersModule, YunoListItemComponent],
	template: `
		@if (isLoading()) {
			<li class="flex w-full items-center justify-center">
				<yuno-spinner></yuno-spinner>
			</li>
		} @else {
			<yuno-ui-list-item>
				<span class="p-4">No results found</span>
			</yuno-ui-list-item>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoListItemLoaderComponent {
	isLoading = input.required<boolean>();
}
