import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-ui-header',
	standalone: true,
	imports: [],
	template: `
		<div class="flex w-full flex-row gap-2">
			<div class="flex-1 truncate">
				<h1 class="truncate empty:hidden">{{ title }}</h1>
				<h4 class="truncate empty:hidden">{{ category }}</h4>
			</div>
			<div class="flex items-end justify-end gap-2 empty:hidden">
				<ng-content select="[buttons]"></ng-content>
			</div>
		</div>

		<div class="flex w-full gap-2 empty:hidden">
			<ng-content select="[bottomRow]"></ng-content>
		</div>
	`,
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoUiHeaderComponent {
	@Input() title: string;
	@Input() category: string | undefined | null;
}
